import { Container, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { RoutePath } from "../routes";

export default function Page404() {
  const theme = useTheme();
  return (
    <Container maxWidth="sm" sx={{ mt: 15 }}>
      <Stack sx={{ gap: 3, alignItems: "center" }}>
        <Typography variant="h1">404</Typography>
        <Typography
          variant="h6"
          component="span"
          color={theme?.custom?.secondaryBackgroundTextColorName}
        >
          Page not found
        </Typography>
        <Link component={RouterLink} to={RoutePath.ROOT}>
          Go to home page
        </Link>
      </Stack>
    </Container>
  );
}
