import React, { memo } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useWindowSize } from "usehooks-ts";

// at max screen size and min word length, the variant is h6
// at min screen size and max word length, the variant is body2

// x axis is word length
// y axis is screen size
const grid = [
  //WL: 12, 10,     8,       6,         0       // screen size
  ["body2", "body2", "body2", "body2", "body2"], // 500
  ["body2", "body2", "body1", "h6", "h6"], // 600
  ["body1", "body1", "h6", "h6", "h6"], // 960
  ["h6", "h6", "h6", "h6", "h6"], // 1280
  ["h6", "h6", "h6", "h6", "h6"], // 1920
];

const getVariantFromWordLengthAndScreenSize = (
  wordLength: number,
  screenSize: number
) => {
  // make a grid with x axis being word length and y axis being screen size
  const wordLengthBreakpoints = [12, 10, 8, 6, 0];
  const screenSizeBreakpoints = [600, 960, 1280, 1920];

  // find the correct variant based on word length and screen size
  let variant;
  for (let i = 0; i < screenSizeBreakpoints.length; i++) {
    if (screenSize <= screenSizeBreakpoints[i]) {
      for (let j = 0; j < wordLengthBreakpoints.length; j++) {
        if (wordLength >= wordLengthBreakpoints[j]) {
          variant = grid[i][j];
          break;
        }
      }
      break;
    }
  }

  if (!variant) {
    throw new Error(
      `Could not find variant for word length ${wordLength} and screen size ${screenSize}`
    );
  }

  return variant;
};

export default function WordOptions({
  title,
  chosenWords,
}: {
  title: string;
  chosenWords: string[];
}) {
  const windowSize = useWindowSize();
  const theme = useTheme();

  return (
    <Grid container columnSpacing={2} sx={{ mt: 1 }}>
      {chosenWords.map((word, index) => {
        const variant = getVariantFromWordLengthAndScreenSize(
          word.length,
          windowSize.width
        );
        return (
          <Grid xs={4} key={`${index}${word}`}>
            <Typography
              variant={variant as any}
              color={theme.custom?.secondaryBackgroundTextColorName}
            >
              {word}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}
