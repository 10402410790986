import React from "react";
import { Stack } from "@mui/material";

import { rhymeCategories } from "../constants/nouns";
import WordOptions from "./WordOptions";
import VerticalStream from "./VerticalStream";
import { shuffleList } from "../utils";

const chooseWordIndices = (words: string[], nToChoose: number) => {
  const indices: number[] = [];
  while (indices.length < nToChoose) {
    const index = Math.floor(Math.random() * words.length);
    if (!indices.includes(index)) {
      indices.push(index);
    }
  }
  return indices;
};

export default function WordStream({
  consecutiveRounds,
  shouldChangeEndChoices,
}: {
  consecutiveRounds: number;
  shouldChangeEndChoices: number; // this is a hack to force children to change
}) {
  const renderChildren = React.useMemo(() => {
    const categoriesMeetingWordCount = rhymeCategories.filter(
      (category) => category.words.length >= 16
    );
    const shuffledCategories = shuffleList(categoriesMeetingWordCount);
    const endChoices = shuffledCategories.map((category) => category.words);
    const children: React.ReactNode[] = [];
    endChoices.forEach((words) => {
      Array.from({ length: 4 }).forEach(() => {
        const chosenWords = chooseWordIndices(words, 3).map(
          (index) => words[index]
        );
        children.push(
          <WordOptions
            key={chosenWords.join("")}
            title={words[0]}
            chosenWords={chosenWords}
          />
        );
      });
    });
    return [null, ...children];
  }, [shouldChangeEndChoices]); // this is a hack to force children to change

  return (
    <Stack sx={{ gap: 5 }}>
      <VerticalStream consecutiveRounds={consecutiveRounds}>
        {renderChildren}
      </VerticalStream>
    </Stack>
  );
}
