import React from "react";
import { Box } from "@mui/material";
import { animated, useSpring, easings } from "@react-spring/web";

import { Typography } from "@mui/material";

const boxHeight = "8rem";
const transitionDuration = 100;
/**
 *
 * The children will be rendered as new components everytime so they can't be memoized. You can't render a component and then pass it to another component and not expect it to render it.
 * They should not have any state or randomness/side effects.
 */
export default function VerticalStream({
  consecutiveRounds,
  children,
}: {
  consecutiveRounds: number;
  children: React.ReactNode[];
}) {
  const [firstElementSprings, firstElementSpringsApi] = useSpring(() => ({
    opacity: 1,
  }));
  const [secondElementSprings, secondElementSpringsApi] = useSpring(() => ({
    top: boxHeight,
  }));
  const [thirdElementSprings, thirdElementSpringsApi] = useSpring(() => ({
    opacity: 0,
  }));

  React.useEffect(() => {
    firstElementSpringsApi.start({
      from: { opacity: 1 },
      to: { opacity: 0 },
      config: { duration: transitionDuration, easing: easings.easeOutBack },
    });
    secondElementSpringsApi.start({
      from: {
        top: boxHeight,
      },
      to: { top: "0rem" },
      config: { duration: transitionDuration },
    });
    thirdElementSpringsApi.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        duration: transitionDuration,
        easing: easings.easeInBack,
      },
    });
  }, [
    consecutiveRounds,
    firstElementSpringsApi,
    secondElementSpringsApi,
    thirdElementSpringsApi,
  ]);

  const currentIndex = consecutiveRounds;

  const elementOne = children[currentIndex - 1];
  const elementTwo = children[currentIndex];
  const elementThree = children[currentIndex + 1];

  const placeHolderForSmoothTransition = (
    <Typography variant="h4" style={{ opacity: 0 }}>
      p
    </Typography>
  );
  return (
    <Box
      style={{ position: "relative" }}
      sx={{
        "& > *": {
          height: boxHeight,
        },
      }}
    >
      <animated.div style={{ ...firstElementSprings }}>
        {placeHolderForSmoothTransition}
        {elementOne}
      </animated.div>
      <animated.div
        style={{
          position: "absolute",
          width: "100%",
          ...secondElementSprings,
        }}
      >
        {elementTwo && (
          <>
            <Typography variant="h4">Now</Typography>
            {elementTwo}
          </>
        )}
      </animated.div>
      <animated.div style={{ ...thirdElementSprings }}>
        {elementThree && (
          <>
            <Typography variant="h4">Next</Typography>
            {elementThree}
          </>
        )}
      </animated.div>
    </Box>
  );
}
