import React from "react";
import { Box } from "@mui/system";
import {
  Stack,
  Container,
  Button,
  TextField,
  Typography,
  ListItem,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { RoutePath } from "../routes";
import { useMaxPlayers } from "../hooks";
import Logo from "../components/Logo";

export default function Setup() {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const maxNumberOfPlayers = useMaxPlayers({ containerRef });
  const navigate = useNavigate();
  const [numberPlayers, setNumberPlayers] = React.useState(4);

  React.useEffect(() => {
    if (numberPlayers > maxNumberOfPlayers) {
      setNumberPlayers(maxNumberOfPlayers);
    }
    // do not add maxNumberOfPlayers to the dependency array as we don't want to make ui look unresponsive
  }, [maxNumberOfPlayers]);

  const link =
    "https://www.youtube.com/watch?v=WXXfhjjoons&list=PLShwU5XBXPLcl1q9vUlkr-MIHUAzkcaB3&index=1";

  return (
    <Container ref={containerRef}>
      <Container maxWidth="sm">
        <Stack sx={{ mt: 10, gap: 3 }}>
          <Logo />
          <Typography variant="body2" component="div">
            Rap Train is a free game. The player with the highest points at the
            end of your session wins.
            <ul style={{ paddingLeft: "1.5rem" }}>
              <li>
                Earn points by pressing COMBO upon finishing your line with
                words under 'Now'.{" "}
              </li>
              <li>Consecutive lines earn exponentially more points</li>
              <li>If you fumble, press PASS and move on to the next player.</li>
            </ul>
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            id="outlined-number"
            value={numberPlayers}
            onChange={(e) => {
              setNumberPlayers(parseInt(e.target.value));
            }}
            label="Number of Players"
            helperText={`Max ${maxNumberOfPlayers} players on your screen size`}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            inputProps={{
              min: 1,
              max: maxNumberOfPlayers,
              step: 1,
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigate(RoutePath.GAME + "?numberPlayers=" + numberPlayers);
              }}
            >
              Start
            </Button>
          </Box>
          <Box sx={{ marginTop: "auto" }}>
            <Typography variant="body2">
              Here's a playlist of our current favorite beats:{" "}
              <a href={link}>{link}</a>
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
}
