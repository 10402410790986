import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";

import Main from "./pages/main";
import Setup from "./pages/setup";
import Game from "./pages/game";

import Page404 from "./fallback_pages/Page404";

import { useThemeInitializer } from "./theme";
import { RoutePath } from "./routes";
import ErrorBoundary from "./fallback_pages/ErrorBoundary";
import ErrorPage from "./fallback_pages/ErrorPage";

const wrapInErrorBoundary = (element: React.ReactNode) => (
  <ErrorBoundary fallback={<ErrorPage message="Something went wrong" />}>
    {element}
  </ErrorBoundary>
);
const router = createBrowserRouter([
  {
    path: RoutePath.ROOT,
    element: wrapInErrorBoundary(<Main />),
    // children here will have footer
    children: [
      {
        path: RoutePath.ROOT,
        element: wrapInErrorBoundary(<Setup />),
      },
      {
        path: RoutePath.GAME,
        element: wrapInErrorBoundary(<Game />),
      },
      {
        path: RoutePath.PAGE404,
        element: <Page404 />,
      },
    ],
  },
]);

function App() {
  const theme = useThemeInitializer();
  if (!theme) return <></>;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
