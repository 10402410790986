import React from "react";
import { Box } from "@mui/system";
import { Stack, Container, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import ScoreTable from "../components/ScoreTable";
import { Player, TargetPosition } from "../types/games";
import ErrorPage from "../fallback_pages/ErrorPage";
import { useMaxPlayers } from "../hooks";
import WordStream from "../components/WordStream";
import Logo from "../components/Logo";
import { shuffleList } from "../utils";
import ComboHypeAnimation from "../components/ComboHypeAnimation";

const targetTops = [
  "-35rem",
  "-30rem",
  "-25rem",
  "-20rem",
  "-15rem",
  "-10rem",
  "-5rem",
  "0rem",
  "2.5rem",
  "5rem",
  "7.5rem",
  "10rem",
  "12.5rem",
  "15rem",
  "17.5rem",
  "20rem",
];
const targetLefts = [
  "-50rem",
  "-45rem",
  "-40rem",
  "-35rem",
  "-30rem",
  "-25rem",
  "-20rem",
  "-15rem",
  "-10rem",
  "-5rem",
  "0rem",
  "5rem",
  "10rem",
  "15rem",
  "20rem",
  "25rem",
  "30rem",
  "35rem",
];
const targetRotates = [
  "0deg",
  "0deg",
  "0deg",
  "0deg",
  "0deg",
  "0deg",
  "30deg",
  "60deg",
  "90deg",
  "120deg",
  "150deg",
  "180deg",
  "210deg",
  "240deg",
  "270deg",
  "300deg",
  "330deg",
  "360deg",
];

const targetPositionsGenerated: TargetPosition[] = targetTops
  .map((top) =>
    targetLefts.map((left) => ({
      top,
      left,
      rotate: targetRotates[Math.floor(Math.random() * targetRotates.length)],
    }))
  )
  .flat();

export default function Game() {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const maxNumberOfPlayers = useMaxPlayers({ containerRef });

  let [searchParams, setSearchParams] = useSearchParams();
  const numberPlayers = searchParams.get("numberPlayers") || "4";
  const [players, setPlayers] = React.useState<Player[]>(
    Array.from({ length: parseInt(numberPlayers) }, (_, i) => ({
      name: `P${i + 1}`,
      isCurrentPlayer: i === 0,
      currentConsecutiveRounds: 0,
      score: 0,
    }))
  );

  const [round, setRound] = React.useState(0);

  const currentPlayerIndex = players.findIndex(
    (player) => player.isCurrentPlayer
  );
  const currentPlayerConsecutiveRounds =
    players[currentPlayerIndex].currentConsecutiveRounds;

  const targetPositionIndices = Array.from(
    { length: targetPositionsGenerated.length },
    (_, i) => i
  );
  const targetPositionIndicesShuffled = shuffleList(targetPositionIndices);

  const [shouldChangeEndChoices, setShouldChangeEndChoices] = React.useState(0);

  if (players.length > maxNumberOfPlayers) {
    return (
      <ErrorPage
        message={`The maximum number of players for your screen size is ${maxNumberOfPlayers}.`}
        tip={
          "Reduce the number of players. Or if you really want to make this work and use chrome, try artifically increasing window size https://ffeathers.wordpress.com/2016/04/16/set-size-of-browser-inner-window-for-screenshots-in-chrome/#:~:text=Open%20Chrome%20Developer%20Tools.,right%20next%20to%20the%20dropdown."
        }
        containerRef={containerRef}
      />
    );
  }

  return (
    <Container sx={{ display: "flex", overflow: "hidden" }} ref={containerRef}>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          paddingY: 3,
          margin: "auto",
        }}
      >
        <Stack sx={{ gap: 6, minWidth: "320px" }}>
          <Logo />
          {round > 0 && (
            <WordStream
              consecutiveRounds={
                players[currentPlayerIndex].currentConsecutiveRounds + 1
              }
              shouldChangeEndChoices={shouldChangeEndChoices}
            />
          )}

          <Stack sx={{ marginTop: "auto", gap: 2 }}>
            {round === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setRound(round + 1);
                  }}
                >
                  Start
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: 4,
                }}
              >
                <Button
                  disableElevation={true}
                  color="primary"
                  size="large"
                  onClick={() => {
                    setPlayers((players) => {
                      const newPlayers = [...players];
                      newPlayers[currentPlayerIndex].isCurrentPlayer = false;
                      newPlayers[
                        currentPlayerIndex
                      ].currentConsecutiveRounds = 0;
                      const nextPlayerIndex =
                        (currentPlayerIndex + 1) % players.length;
                      newPlayers[nextPlayerIndex].isCurrentPlayer = true;
                      return newPlayers;
                    });
                    setRound(round + 1);
                    setShouldChangeEndChoices(shouldChangeEndChoices + 1);
                  }}
                >
                  Pass
                </Button>
                <Button
                  variant="contained"
                  disableElevation={true}
                  size="large"
                  onClick={() => {
                    setPlayers((players) => {
                      const newPlayers = [...players];
                      const addedScore =
                        2 **
                        newPlayers[currentPlayerIndex].currentConsecutiveRounds;
                      newPlayers[currentPlayerIndex].score += addedScore;
                      newPlayers[currentPlayerIndex].currentConsecutiveRounds++;
                      return newPlayers;
                    });
                    setRound(round + 1);
                  }}
                >
                  Combo
                  {targetPositionIndicesShuffled
                    .slice(0, currentPlayerConsecutiveRounds)
                    .map((targetPositionIdx) => {
                      return (
                        <ComboHypeAnimation
                          key={targetPositionIdx}
                          counter={currentPlayerConsecutiveRounds}
                          targetPosition={
                            targetPositionsGenerated[targetPositionIdx]
                          }
                        />
                      );
                    })}
                </Button>
              </Box>
            )}
            <ScoreTable players={players} />
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}
