import React from "react";
import { useWindowSize } from "usehooks-ts";
import { WIDTH_PER_PLAYER } from "../constants/game";

export function useElementSize(elementRef: React.RefObject<HTMLDivElement>) {
  const [elementSize, setElementSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (!elementRef.current) return;
    const observer = new ResizeObserver(() => {
      if (!elementRef.current) return;
      const elementSize = elementRef.current.getBoundingClientRect();
      setElementSize({
        width: elementSize.width,
        height: elementSize.height,
      });
    });
    observer.observe(elementRef.current);
    return () => {
      observer.disconnect();
    };
  }, [elementRef]);

  return elementSize;
}

/** Determines the maximum number of players that can fit in the container.
 *
 * Puts it in context so that it can be used by other components.
 */
export function useMaxPlayers({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const [maxNumberOfPlayers, setMaxNumberOfPlayers] = React.useState(100);
  const windowSize = useWindowSize();

  React.useEffect(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.clientWidth;
    // each player needs WIDTH_PER_PLAYER + padding pixels
    setMaxNumberOfPlayers(Math.floor(containerWidth / (WIDTH_PER_PLAYER + 10)));
  }, [setMaxNumberOfPlayers, containerRef, windowSize.width]);

  return maxNumberOfPlayers;
}
