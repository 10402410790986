import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { ReactComponent as LogoRounded } from "../icons/LogoRounded.svg";

export default function Logo() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "right",
        alignItems: "start",
      }}
    >
      <Typography variant="h6" component="h1">
        Rap Train
      </Typography>
      <Box sx={{ height: "48px", ml: 2 }}>
        <LogoRounded style={{ width: "100%", height: "100%" }} />
      </Box>
    </Box>
  );
}
