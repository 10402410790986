// a e i o u
type Node = {
  exampleWord: string;
  words: string[];
};
export const rhymeCategories: Node[] = [
  {
    exampleWord: "cash",
    words: [
      "cash",
      "flash",
      "trash",
      "stash",
      "splash",
      "crash",
      "dash",
      "mash",
      "smash",
      "fast",
      "mass",
      "rash",
      "glass",
    ],
  },
  {
    exampleWord: "glasses",
    words: [
      "glasses",
      "passes",
      "asses",
      "classes",
      "masses",
      "ashes",
      "past this",
      "fastest",
      "blast this",
      "atlas",
    ],
  },
  {
    exampleWord: "hat",
    words: ["hat", "rat", "chat", "that", "bat", "at", "pat", "last", "stats"],
  },
  {
    exampleWord: "backpack",
    words: [
      "backpack",
      "blackjack",
      "crackhead",
      "backtrack",
      "have that",
      "half black",
      "backtrack",
      "fast track",
      "flashback",
      "jack black",
      "last laugh",
      "last track",
      "match that",
      "pass that",
      "past that",
      "quack quack",
    ],
  },
  {
    exampleWord: "bag",
    words: ["bag", "tag", "lag", "drag", "brag", "flag"],
  },

  {
    exampleWord: "cancel",
    words: [
      "cancel",
      "candle",
      "handle",
      "scandal",
      "vandal",
      "damsel",
      "mantle",
      "handle",
      "lambo",
      "rambo",
      "man tho",
      "camera",
      "angles",
      "sample",
      "example",
      "sandals",
      "tangled",
      "standstill",
      "answer",
      "pants up",
      "meant to",
      "cancer",
    ],
  },
  {
    exampleWord: "match",
    words: ["match", "catch", "hatch", "scratch", "snatch"],
  },
  {
    exampleWord: "are",
    words: [
      "are",
      "far",
      "star",
      "bar",
      "car",
      "heart",
      "charred",
      "park",
      "shark",
      "dark",
      "mark",
      "chart",
      "start",
      "part",
      "bark",
      "narc",
    ],
  },
  {
    exampleWord: "walk",
    words: ["walk", "talk", "chalk", "squawk", "watch", "taco", "awkward"],
  },
  {
    exampleWord: "all",
    words: ["all", "ball", "call", "fall", "mall", "small", "wall"],
  },
  {
    exampleWord: "cake",
    words: [
      "cake",
      "bake",
      "fake",
      "lake",
      "rake",
      "snake",
      "take",
      "wake",
      "ache",
      "break",
      "flake",
      "make",
      "quake",
      "shake",
      "stake",
      "ache",
      "brake",
      "Drake",
      "flake",
      "brake",
      "great",
      "late",
      "state",
      "wait",
    ],
  },
  {
    exampleWord: "chair",
    words: [
      "chair",
      "bear",
      "care",
      "dare",
      "fair",
      "hair",
      "pair",
      "rare",
      "stare",
      "air",
      "wear",
      "where",
      "there",
      "aware",
      "share",
    ],
  },
  {
    exampleWord: "bed",
    words: [
      "bed",
      "dead",
      "head",
      "lead",
      "red",
      "said",
      "thread",
      "bread",
      "ahead",
    ],
  },
  {
    exampleWord: "me",
    words: [
      "me",
      "be",
      "see",
      "free",
      "tree",
      "three",
      "we",
      "he",
      "she",
      "wee",
      "beach",
      "key",
      "sheep",
      "sea",
      "weed",
      "bleed",
      "agree",
      "knee",
      "leave",
      "please",
      "sneeze",
      "beach",
      "beat",
      "week",
      "fees",
      "peace",
    ],
  },

  {
    exampleWord: "dream",
    words: [
      "dream",
      "cream",
      "seem",
      "stream",
      "scream",
      "team",
      "beam",
      "theme",
      "scene",
      "lean",
      "mean",
      "seen",
      "green",
      "clean",
      "fiend",
      "been",
      "halloween",
    ],
  },
  {
    exampleWord: "feature",
    words: [
      "feature",
      "need ya",
      "amensia",
      "defeat ya",
      "beat ya",
      "seizure",
      "freezer",
      "mona lisa",
      "diva",
      "lethal",
      "ether",
      "neither",
      "believe her",
      "beaver",
      "lean up",
      "nissan",
      "cesar",
      "ibiza",
      "PETA",
      "fifa",
      "meter",
      "Beiber",
      "mamacita",
    ],
  },
  {
    exampleWord: "easy",
    words: [
      "easy",
      "need me",
      "meet me",
      "avicii",
      "believe me",
      "leave me",
      "habibi",
      "tv",
      "cd",
      "cheesy",
      "breezy",
      "bleeding",
      "feeding",
      "reading",
      "leading",
      "nicki",
      "hehe",
      "peachy",
      "cc",
      "gg",
      "freaky",
    ],
  },
  {
    exampleWord: "money",
    words: ["money", "bunny", "funny", "honey", "on me"],
  },
  {
    exampleWord: "work",
    words: [
      "work",
      "jerk",
      "perk",
      "shirk",
      "smirk",
      "twerk",
      "dirt",
      "shirt",
      "hurt",
      "alert",
      "assert",
      "convert",
      "desert",
      "exert",
    ],
  },
  {
    exampleWord: "under",
    words: [
      "under",
      "thunder",
      "wonder",
      "honda",
      "hunger",
      "jumpers",
      "squander",
      "stunned her",
    ],
  },
  {
    exampleWord: "hotel",
    words: ["hotel", "motel", "don't tell", "oh well", "coat tail"],
  },
  {
    exampleWord: "resort",
    words: [
      "resort",
      "snort",
      "abort",
      "report",
      "distort",
      "retort",
      "short",
      "sport",
      "court",
      "short",
      "sort",
      "report",
      "support",
    ],
  },
  {
    exampleWord: "hostel",
    words: ["hostel", "apostle"],
  },
  {
    exampleWord: "couch",
    words: ["couch", "ouch", "pouch", "slouch"],
  },
  {
    exampleWord: "sofa",
    words: ["sofa", "loafa"],
  },
  {
    exampleWord: "rum",
    words: ["rum", "thumb", "run"],
  },
  {
    exampleWord: "in",
    words: ["in", "gin", "win", "thin", "skin"],
  },
  {
    exampleWord: "toilet",
    words: ["toilet", "boil it", "spoil it", "soil it"],
  },
  {
    exampleWord: "sun",
    words: ["sun", "fun", "run", "bun", "gun", "done", "one", "none", "on"],
  },
  {
    exampleWord: "hilton",
    words: ["hilton", "building", "milton"],
  },
  {
    exampleWord: "tent",
    words: [
      "tent",
      "rent",
      "sent",
      "spent",
      "went",
      "bent",
      "lent",
      "scent",
      "event",
      "pen",
    ],
  },
  {
    exampleWord: "not",
    words: ["not", "hot", "pot", "lot", "shot", "got", "knot", "what"],
  },
  {
    exampleWord: "Texas",
    words: ["Texas", "nexus", "lexus", "blessed us", "next is", "ex is"],
  },
  {
    exampleWord: "motor",
    words: ["motor", "owed her"],
  },
  {
    exampleWord: "tesla",
    words: ["tesla", "vespa", "next to", "blessed up"],
  },
  {
    exampleWord: "audi",
    words: [
      "audi",
      "howdy",
      "saudi",
      "outtie",
      "bugatti",
      "about it",
      "safari",
      "sorry",
      "ferrari",
      "wallet",
      "wall-e",
      "molly",
      "party",
      "atari",
    ],
  },
  {
    exampleWord: "more",
    words: [
      "more",
      "porche",
      "worst",
      "divorce",
      "store",
      "horse",
      "more",
      "floor",
      "bore",
      "pour",
      "four",
    ],
  },
  {
    exampleWord: "taxi",
    words: ["taxi", "ask me", "backseat", "actually"],
  },
  {
    exampleWord: "loser",
    words: [
      "loser",
      "uber",
      "scooter",
      "computer",
      "future",
      "tuba",
      "shooters",
      "maneuver",
    ],
  },
  {
    exampleWord: "like",
    words: [
      "like",
      "bike",
      "hike",
      "strike",
      "kite",
      "night",
      "right",
      "tight",
      "tie",
      "ice",
      "bite",
      "drive",
      "alive",
      "arrive",
      "survive",
      "thrive",
      "dive",
      "five",
      "guys",
      "knife",
      "ride",
      "side",
    ],
  },
  {
    exampleWord: "man",
    words: [
      "man",
      "van",
      "understand",
      "pants",
      "hands",
      "can",
      "again",
      "hand",
    ],
  },
  {
    exampleWord: "lotion",
    words: ["lotion", "motion", "ocean", "potion"],
  },
  {
    exampleWord: "dollar",
    words: ["dollar", "holler", "baller", "call her", "taller", "smaller"],
  },
  {
    exampleWord: "big",
    words: [
      "big",
      "with",
      "sick",
      "quick",
      "stick",
      "trick",
      "kick",
      "brick",
      "d***",
    ],
  },
  {
    exampleWord: "plastic",
    words: ["plastic", "past it", "drastic", "classic"],
  },
  {
    exampleWord: "hood",
    words: ["hood", "good", "wood", "stood", "could", "should", "would"],
  },
  {
    exampleWord: "bone",
    words: [
      "bone",
      "home",
      "on",
      "stone",
      "alone",
      "phone",
      "tone",
      "known",
      "own",
      "bone",
      "alone",
      "moan",
      "grown",
    ],
  },
  {
    exampleWord: "steal",
    words: ["steal", "meal", "steel", "deal", "real", "feel", "seal", "field"],
  },
  {
    exampleWord: "diamond",
    words: ["diamond", "behind it", "signed it", "find it"],
  },
  {
    exampleWord: "out",
    words: [
      "out",
      "shout",
      "doubt",
      "about",
      "mouth",
      "south",
      "cloud",
      "house",
    ],
  },
  {
    exampleWord: "water",
    words: [
      "water",
      "daughter",
      "slaughter",
      "bought her",
      "offer",
      "natasha",
      "nut job",
      "doctor",
      "ha ha",
      "wallah",
      "non stop",
      "ta tas",
      "knock offs",
      "drop top",
      "c*** block",
      "cha cha",
      "hot shot",
      "proper",
      "lock jaw",
      "hot tub",
      "knock knock",
      "crock pot",
    ],
  },
  {
    exampleWord: "cabin",
    words: ["cabin", "has been", "happen", "captain"],
  },
  {
    exampleWord: "valley",
    words: ["valley", "alley"],
  },
  {
    exampleWord: "pill",
    words: [
      "pill",
      "will",
      "still",
      "fill",
      "killed",
      "skill",
      "thrill",
      "deal",
      "hill",
    ],
  },
  {
    exampleWord: "mountain",
    words: ["mountain", "fountain", "counting", "found him", "about then"],
  },
  {
    exampleWord: "boulder",
    words: [
      "boulder",
      "hold her",
      "older",
      "sold her",
      "told her",
      "soldier",
      "colder",
    ],
  },
  {
    exampleWord: "push me",
    words: ["push me", "cushy", "bushy", "tushy", "pu***", "pushy"],
  },
  {
    exampleWord: "forest",
    words: ["forest", "chorus", "florist", "tortoise"],
  },
  {
    exampleWord: "pond",
    words: ["pond", "beyond", "bond", "respond"],
  },
  {
    exampleWord: "river",
    words: [
      "river",
      "giver",
      "shiver",
      "quiver",
      "deliver",
      "with her",
      "liquor",
      "glitter",
      "sister",
    ],
  },
  {
    exampleWord: "pool",
    words: ["pool", "school", "fool", "tool", "jewel", "cruel"],
  },
  {
    exampleWord: "stadium",
    words: ["stadium", "cranium", "titanium", "radio"],
  },

  {
    exampleWord: "goal",
    words: ["goal", "soul", "roll", "toll", "whole", "pole", "hole", "role"],
  },
  {
    exampleWord: "net",
    words: [
      "net",
      "set",
      "get",
      "wet",
      "vet",
      "yet",
      "let",
      "met",
      "bet",
      "debt",
      "forget",
      "pet",
      "sweat",
      "threat",
    ],
  },
  {
    exampleWord: "puck",
    words: ["puck", "luck", "stuck", "suck", "duck", "buck", "yuck"],
  },
  {
    exampleWord: "bracelet",
    words: ["bracelet", "face it", "place it", "chase it", "laced with"],
  },

  {
    exampleWord: "pocket",
    words: ["pocket", "rocket", "lock it", "block it", "shock it"],
  },
  {
    exampleWord: "mess",
    words: [
      "mess",
      "less",
      "guess",
      "stress",
      "dress",
      "yes",
      "press",
      "address",
      "best",
      "desk",
      "chest",
      "test",
      "rest",
    ],
  },
  {
    exampleWord: "train",
    words: ["train", "brain", "plain", "main", "again", "lane", "plane"],
  },
  {
    exampleWord: "shipping",
    words: [
      "shipping",
      "dipping",
      "tripping",
      "ripping",
      "slipping",
      "kissing",
      "pimpin",
      "pippen",
      "whipping",
      "missing",
      "kitten",
    ],
  },
  {
    exampleWord: "ship",
    words: [
      "ship",
      "dip",
      "trip",
      "rip",
      "slip",
      "kiss",
      "pimp",
      "pip",
      "whip",
      "miss",
    ],
  },
  {
    exampleWord: "boat",
    words: ["boat", "oath", "float", "coat", "throat", "note", "goat", "quote"],
  },
  {
    exampleWord: "crutches",
    words: [
      "crutches",
      "touches",
      "dutches",
      "hutches",
      "clutches",
      "duccheesss",
    ],
  },
  {
    exampleWord: "ink",
    words: ["ink", "think", "drink", "link", "sink", "stink", "pink", "wink"],
  },
  {
    exampleWord: "stamp",
    words: ["stamp", "ramp", "camp", "lamp", "damp", "clamp", "tramp"],
  },
  {
    exampleWord: "mail",
    words: [
      "mail",
      "real",
      "nail",
      "fail",
      "whale",
      "stale",
      "male",
      "christian bale",
    ],
  },
  {
    exampleWord: "stapler",
    words: ["stapler", "paper", "later", "hater", "greater", "sedate her"],
  },
  {
    exampleWord: "belt",
    words: ["belt", "felt", "melt"],
  },
  {
    exampleWord: "necklace",
    words: ["necklace", "reckless", "check this", "breakfast"],
  },
  {
    exampleWord: "nurse",
    words: ["nurse", "worse", "curse", "purse", "verse", "reverse", "first"],
  },
  {
    exampleWord: "ring",
    words: [
      "ring",
      "sing",
      "bring",
      "thing",
      "king",
      "swing",
      "sting",
      "bling",
    ],
  },
  {
    exampleWord: "glove",
    words: ["glove", "love", "shove", "above", "of"],
  },
  {
    exampleWord: "flower",
    words: ["flower", "power", "tower", "sour", "hour", "shower", "cower"],
  },
  {
    exampleWord: "cup",
    words: ["cup", "up", "dump", "pump", "jump", "stump", "rump", "hump"],
  },
  {
    exampleWord: "shovel",
    words: ["shovel", "brothers", "mothers", "others", "lovers", "rubbers"],
  },
  {
    exampleWord: "movie",
    words: ["movie", "groupie"],
  },
  {
    exampleWord: "song",
    words: ["song", "long", "wrong", "thong"],
  },
  {
    exampleWord: "same",
    words: ["same", "blame", "shame", "came", "aim"],
  },
  {
    exampleWord: "laptop",
    words: ["laptop", "hats off", "back off", "hands on", "tampon"],
  },
  {
    exampleWord: "book",
    words: ["book", "look", "took", "hook", "crook", "cook", "nook"],
  },
  {
    exampleWord: "tissue",
    words: ["tissue", "issue", "kiss you", "miss you", "dis you"],
  },
  {
    exampleWord: "enough",
    words: [
      "enough",
      "tough",
      "rough",
      "stuff",
      "buff",
      "cuff",
      "puff",
      "bluff",
    ],
  },
  {
    exampleWord: "toothbrush",
    words: ["toothbrush", "too much", "new stuff", "do stuff", "blue stuff"],
  },
  {
    exampleWord: "soap",
    words: ["soap", "hope", "pope", "nope", "cope"],
  },
  {
    exampleWord: "shoe",
    words: ["shoe", "too", "you", "through", "true"],
  },
  {
    exampleWord: "window",
    words: ["window", "when though?"],
  },
  {
    exampleWord: "puppy",
    words: ["puppy", "off beat", "roughly", "love me"],
  },
  {
    exampleWord: "girl",
    words: ["girl", "world", "curl", "hurl", "pearl", "whirl"],
  },
  {
    exampleWord: "drugs",
    words: ["drugs", "hugs", "thugs", "bugs", "mugs", "shrugs"],
  },
  {
    exampleWord: "smoke",
    words: ["smoke", "joke", "poke", "choke", "stroke"],
  },
  {
    exampleWord: "stocks",
    words: ["stocks", "rocks", "blocks", "awks", "ox"],
  },
  {
    exampleWord: "bottle",
    words: ["bottle", "model", "throttle", "follow", "hollow", "swallow"],
  },
  {
    exampleWord: "voodoo",
    words: ["voodoo", "you do", "who knew", "new crew", "true blue", "boo hoo"],
  },
  {
    exampleWord: "magenta",
    words: [
      "magenta",
      "enter",
      "remember",
      "center",
      "bend her",
      "Sex uh",
      "messed up",
      "blessed up",
      "next up",
      "benzos",
      "mentor",
      "romancer",
      "dancers",
      "enter",
      "pent up",
      "banter",
    ],
  },
  {
    exampleWord: "back door",
    words: ["back door", "that for", "rap more"],
  },
  {
    exampleWord: "butt hole",
    words: ["what though", "nut though", "uh oh", "cuckold", "up though"],
  },
  {
    exampleWord: "evil",
    words: ["evil", "beatles", "need those", "people", "sequel"],
  },
  {
    exampleWord: "bicycle",
    words: [
      "bicycle",
      "time to kill",
      "by the bill",
      "rhyming still",
      "popped a pill",
      "robbed the till",
      "like the grill",
      "buy the mill",
      "by the mill",
    ],
  },
  {
    exampleWord: "able",
    words: [
      "able",
      "cable",
      "made of",
      "maple",
      "make up",
      "staple",
      "navel",
      "naples",
    ],
  },
  {
    exampleWord: "miami",
    words: ["miami", "understand me", "grammy", "panties", "family"],
  },
  {
    exampleWord: "your man",
    words: [
      "your man",
      "tour van",
      "more than",
      "four grand",
      "program",
      "know that",
    ],
  },
  {
    exampleWord: "cocky",
    words: ["cocky", "by me", "papi", "i see", "my key", "coffee", "copy"],
  },
  {
    exampleWord: "bore me",
    words: ["bore me", "horny", "morning"],
  },
  {
    exampleWord: "shows",
    words: ["shows", "hoes", "goes", "clothes"],
  },
  {
    exampleWord: "shoes",
    words: ["shoes", "lose", "booze", "new", "move", "choose", "whose"],
  },
];

// export const exampleWordToWords = rhymeCategories.reduce((acc, category) => {
//   acc[category.exampleWord] = category.words;
//   return acc;
// }, {} as { [key: string]: string[] });
