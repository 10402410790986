import { Outlet } from "react-router-dom";

import { Box } from "@mui/system";
import { Stack } from "@mui/material";

export default function Main() {
  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <Outlet />
    </Stack>
  );
}
