import React from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import { Box } from "@mui/system";

import { ReactComponent as LogoNoBackground } from "../icons/LogoNoBackground.svg";
import { TargetPosition } from "../types/games";

export default function ComboHypeAnimation({
  counter, // hack to force re-render, but also used to determine animation duration
  targetPosition,
}: {
  counter: number;
  targetPosition: TargetPosition;
}) {
  const [springs, springsApi] = useSpring(() => ({
    opacity: 0,
    scale: 0.2,
    top: "0rem",
    left: "0rem",
    transform: `rotate(0deg)`,
  }));

  React.useEffect(() => {
    springsApi.start({
      from: {
        opacity: 1,
        scale: 0.2,
        top: "0rem",
        left: "0rem",
        transform: `rotate(0deg)`,
      },
      to: {
        opacity: 0,
        scale: 1,
        top: targetPosition.top,
        left: targetPosition.left,
        transform: `rotate(${targetPosition.rotate})`,
      },
      config: {
        duration: 100 * (100 / counter),
        easing: easings.easeOutCirc,
      },
    });
  }, [counter, springsApi, targetPosition]);

  return (
    <animated.div style={{ position: "absolute", ...springs }}>
      <Box sx={{ height: "8rem", width: "8rem" }}>
        <LogoNoBackground style={{ width: "100%", height: "100%" }} />
      </Box>
    </animated.div>
  );
}
