/**
 *  I use accent instead of dark and light because it should be the opposite in dark mode
 */
export enum ColorMui {
  background = "background",
  backgroundAccent = "grey.200",
  backgroundAccent2 = "grey.600",
  backgroundContrast = "grey.800",
  shadow = "grey.900",
  primary = "primary.main",
  primaryAccent = "primary.light",
  primaryAccent2 = "primary.dark",
  primaryContrast = "primary.contrastText",

  grey400 = "grey.400",
}
