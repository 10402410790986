import React from "react";
import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

import { ColorMui } from "./constants/Styles";

declare module "@mui/material/styles" {
  interface Theme {
    custom?: {
      secondaryBackgroundTextColorName?: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      secondaryBackgroundTextColorName?: string;
    };
  }
}

enum ThemeChoice {
  A = "A",
  B = "B",
}

export const themes: { [key in ThemeChoice]: ThemeOptions } = {
  [ThemeChoice.A]: {
    custom: {
      secondaryBackgroundTextColorName: ColorMui.backgroundAccent2, // this concept is only present in this theme. I think creating a hierarchy of text using colors looks nice
    },
    palette: {
      primary: {
        main: "#ff5722",
      },
    },
  },
  [ThemeChoice.B]: {
    palette: {
      primary: {
        main: "#42B26A",
        contrastText: "#FFFFFF",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none" as const,
            borderRadius: 10,
          },
        },
      },
      // text field
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
    },
  },
};

export function useThemeInitializer() {
  const [theme, setTheme] = React.useState<Theme | null>(null);

  React.useEffect(() => {
    let themeChoice: ThemeChoice = ThemeChoice.A;
    const theme = createTheme(themes[themeChoice]);
    setTheme(theme);
  }, []);

  return theme;
}
