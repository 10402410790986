import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { Player } from "../types/games";
import { Typography, Box } from "@mui/material";
import { WIDTH_PER_PLAYER } from "../constants/game";

export default function ScoreTable({ players }: { players: Player[] }) {
  const columns: GridColDef[] = players.map((player) => ({
    field: player.name,
    headerName: player.name,
    headerAlign: "center",
    align: "center",
    width: WIDTH_PER_PLAYER,
    editable: false,
    sortable: false,
    renderHeader: (params) => (
      <Typography
        align="center"
        variant="h6"
        component="span"
        color={player.isCurrentPlayer ? "grey.900" : "grey.500"}
      >
        {params.field}
      </Typography>
    ),
    renderCell: (params) => (
      <Typography
        align="center"
        variant="body1"
        component="span"
        color={player.isCurrentPlayer ? "grey.900" : "grey.500"}
      >
        {Math.floor(params.value)}
      </Typography>
    ),
  }));
  const rows: GridRowsProp = [
    players.reduce(
      (acc, player) => ({
        ...acc,
        [player.name]: player.score,
      }),
      {
        id: 0,
      }
    ),
  ];

  return (
    <Box sx={{ width: WIDTH_PER_PLAYER * players.length + 2 }}>
      <DataGrid
        columns={columns}
        rows={rows}
        hideFooter={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
      />
    </Box>
  );
}
