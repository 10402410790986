import { Container, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { RoutePath } from "../routes";

export default function ErrorPage({
  message,
  tip,
  containerRef,
}: {
  message: string;
  tip?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}) {
  const theme = useTheme();
  return (
    <Container ref={containerRef}>
      <Container sx={{ mt: 15 }} maxWidth="sm">
        <Stack sx={{ gap: 3, alignItems: "center" }}>
          <Typography variant="h1">Sorry!</Typography>
          <Typography
            align="center"
            variant="h6"
            component="span"
            color={theme?.custom?.secondaryBackgroundTextColorName}
          >
            {message}
          </Typography>
          <Link component={RouterLink} to={RoutePath.ROOT}>
            Go to home page
          </Link>

          {tip && (
            <Typography
              variant="body2"
              component="span"
              color={theme?.custom?.secondaryBackgroundTextColorName}
            >
              {tip}
            </Typography>
          )}
        </Stack>
      </Container>
    </Container>
  );
}
